import { Button } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { DEFAULT_POLL_TIMEOUT, InstanceStateCodes } from '../../constants';
import useApi from '../../hooks/useApi';
import styles from './AdminGeneral.module.css';

function getServerStateDisplayText(stateCode) {
  switch (stateCode) {
    case InstanceStateCodes.PENDING:
      return 'PENDING';
    case InstanceStateCodes.RUNNING:
      return 'RUNNING';
    case InstanceStateCodes.SHUTTING_DOWN:
      return 'SHUTTING_DOWN';
    case InstanceStateCodes.TERMINATED:
      return 'TERMINATED';
    case InstanceStateCodes.STOPPING:
      return 'STOPPING';
    case InstanceStateCodes.STOPPED:
      return 'STOPPED';
    default:
      return 'FETCHING';
  }
}

export default function AdminGeneral() {
  const [riotZoneServerStateCode, setRiotZoneServerStateCode] = useState(null);
  const api = useApi();

  const handleStartRiotZoneServer = async () => {
    try {
      setRiotZoneServerStateCode(await api.startRiotZoneServer());
    } catch (e) {
      console.error(e);
      // TODO: show error in UI
    }
  }

  const handleStopRiotZoneServer = async () => {
    try {
      setRiotZoneServerStateCode(await api.stopRiotZoneServer());
    } catch (e) {
      console.error(e);
      // TODO: show error in UI
    }
  }

  useEffect(() => {
    const update = async () => {
      try {
        setRiotZoneServerStateCode(await api.getRiotZoneServerStateCode());
      } catch (e) {
        console.error(e);
        // TODO: show error in UI
      }
    };

    update();
    const intervalId = setInterval(update, DEFAULT_POLL_TIMEOUT);
    return () => clearInterval(intervalId);
  }, [api]);

  return (
    <>
      <div classnames={styles.state}>
        RiotZone server state: {getServerStateDisplayText(riotZoneServerStateCode)}
      </div>
      <div classnames={styles.actions}>
        <Button onClick={handleStartRiotZoneServer}>Start Riot Zone Server</Button>
        <Button onClick={handleStopRiotZoneServer}>Stop Riot Zone Server</Button>
      </div>
    </>
  );
}

import {
  Button,
  Tabs,
  TabItem,
} from '@aws-amplify/ui-react';
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import withWrappedApiProvider from '../../hoc/withWrappedApiProvider';
import useApi from '../../hooks/useApi';

const TABS = [
  {
    title: 'General',
    path: 'general',
  },
  {
    title: 'Minecraft',
    path: 'minecraft',
  },
];

function getUserDisplayId(user) {
  return user?.attributes?.email ?? user?.attributes?.phone_number ?? 'Admin User';
}

function Admin() {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut, user } = useApi();
  const currentTabIndex = '' + TABS.findIndex(({ path }) => location.pathname.endsWith(`/${path}`))

  function handleTabChange(newTabIndex) {
    navigate(TABS[+newTabIndex].path);
  }

  return (
    <>
      <Button onClick={signOut}>Sign Out ({getUserDisplayId(user)})</Button>
      <Tabs
        currentIndex={currentTabIndex}
        onChange={handleTabChange}
      >
        {TABS.map(({ title, path }) => <TabItem key={path} title={title}/>)}
      </Tabs>
      <Outlet/>
    </>
  );
}

export default withWrappedApiProvider(Admin);

import { useEffect, useRef, useState } from "react";
import styles from './LPVideo.module.css';

export default function LPVideo({ events }) {
  const videoEl = useRef();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    videoEl.current.addEventListener('ended', () => {
      setIsVisible(false);
    });
    videoEl.current.play();
  }, []);

  return !isVisible ? null : (
    <div className={styles.video}>
      <video
        ref={videoEl}
        width="750"
      >
        <source src="https://public-stream-files.s3.amazonaws.com/Solved.mp4" type="video/mp4"/>
      </video>
    </div>
  );
}

import { Signer } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import flow from 'lodash/flow';
import { AWS_REGION } from '../../constants';
import withWrappedApiProvider from '../../hoc/withWrappedApiProvider';
import withTopLevelRouteBehaviors from '../../hoc/withTopLevelRouteBehaviors';
import useApi from '../../hooks/useApi';
import styles from './StreamOverlay.module.css';
import LPVideo from '../LPVideo/LPVideo';

const EXECUTE_API_SERVICE = 'execute-api';
const WS_URL = 'wss://ws.livepal.io/overlays';

const getSignedWsUrl = (credentials) => {
  const accessInfo = {
    access_key: credentials.accessKeyId,
    secret_key: credentials.secretAccessKey,
    session_token: credentials.sessionToken,
  }
  const serviceInfo = {
    region: AWS_REGION,
    service: EXECUTE_API_SERVICE,
  };
  return Signer.signUrl(WS_URL, accessInfo, serviceInfo);
};

function StreamOverlay() {
  const { credentials } = useApi();
  const [logs, setLogs] = useState('');
  const ws = useRef();

  console.log = (...args) => setLogs(l => `${l}\n${args.map(JSON.stringify).join(' ')}`);

  useEffect(() => {
    let didCloseLocally = false;

    const connect = () => {
      ws.current = new WebSocket(getSignedWsUrl(credentials));
      ws.current.onopen = (e) => console.log('lp ws open', e);
      ws.current.onmessage = (e) => console.log('lp ws message', JSON.parse(e.data));
      ws.current.onerror = (e) => console.log('lp ws error', e);
      ws.current.onclose = (e) => {
        console.log('lp ws closed', e);
        if (!didCloseLocally) setTimeout(connect);
      };
    };

    connect();
    
    return () => {
      didCloseLocally = true;
      ws.current.close();
    };
  }, [credentials]);

  useEffect(() => {
    console.log('mount');
    return () => console.log('unmount');
  }, []);

  useEffect(() => {
    if (!window.obsstudio) return;

    // https://github.com/obsproject/obs-browser
    console.log('obs-browser version:', window.obsstudio.pluginVersion);
    window.obsstudio.getControlLevel((level => console.log('Control-level:', level)));
    window.obsstudio.getStatus((status => console.log('Status:', status)));

    const onObsSceneChange = (event) => console.log(`Scene: ${event.detail.name}`);
    window.addEventListener('obsSceneChanged', onObsSceneChange);
    return () => window.removeEventListener('obsSceneChanged', onObsSceneChange);
  }, []);

  return (
    <div className={styles.overlay}>
      <LPVideo/>
      <div>{`${window.innerWidth}x${window.innerHeight}`}</div>
      <pre>{logs}</pre>
    </div>
  );
}

export default flow([
  withWrappedApiProvider,
  withTopLevelRouteBehaviors,
])(StreamOverlay);

import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Divider, Menu, MenuItem } from '@aws-amplify/ui-react';
import styles from './App.module.css';
import withTopLevelRouteBehaviors from '../../hoc/withTopLevelRouteBehaviors';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <div className={styles.app}>
      <div className={styles.header}>
        <Menu
          isOpen={isMenuOpen}
          onOpenChange={setIsMenuOpen}
          menuAlign='start'>
          <Link to='/'>
            <MenuItem className={styles.menuItem} onClick={closeMenu}>Home</MenuItem>
          </Link>
          <Divider/>
          <Link to='admin'>
            <MenuItem className={styles.menuItem} onClick={closeMenu}>Admin</MenuItem>
          </Link>
          <Divider/>
          <Link to='stream-overlay'>
            <MenuItem className={styles.menuItem} onClick={closeMenu}>Stream Overlay</MenuItem>
          </Link>
        </Menu>
      </div>
      <div className={styles.content}>
        <Outlet/>
      </div>
    </div>
  );
}

export default withTopLevelRouteBehaviors(App);

import { Button} from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { DEFAULT_POLL_TIMEOUT, MC_LIST_UUIDS, ServiceStatusCodes } from '../../constants';
import useApi from '../../hooks/useApi';
import styles from './AdminMinecraft.module.css';

function getServiceStatusDisplayText(statusCode) {
  switch (statusCode) {
    case ServiceStatusCodes.RUNNING:
      return 'RUNNING';
    case ServiceStatusCodes.DEAD_A:
      return 'DEAD_A';
    case ServiceStatusCodes.DEAD_B:
      return 'DEAD_B';
    case ServiceStatusCodes.NOT_RUNNING:
      return 'NOT_RUNNING';
    case ServiceStatusCodes.UNKNOWN:
      return 'UNKNOWN';
    default:
      return 'FETCHING';
  }
}

export default function AdminMinecraft() {
  const [minecraftServiceStatusCode, setMinecraftServiceStatusCode] = useState(null);
  const [onlinePlayers, setOnlinePlayers] = useState([]);
  const api = useApi();

  const handleStartMinecraftService = async () => {
    try {
      await api.startMinecraftService();
    } catch (e) {
      console.error(e);
      // TODO: show error in UI
    }
  };

  const handleStopMinecraftService = async () => {
    try {
      await api.stopMinecraftService();
    } catch (e) {
      console.error(e);
      // TODO: show error in UI
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        setMinecraftServiceStatusCode(await api.getMinecraftServiceStatusCode());
      } catch (e) {
        console.error(e);
        // TODO: show error in UI
      }
    };

    update();
    const intervalId = setInterval(update, DEFAULT_POLL_TIMEOUT);
    return () => clearInterval(intervalId);
  }, [api]);

  useEffect(() => {
    const update = async () => {
      if (minecraftServiceStatusCode !== ServiceStatusCodes.RUNNING) return;

      try {
        setOnlinePlayers((await api.executeMinecraftCommands([MC_LIST_UUIDS])).results[0].players);
      } catch (e) {
        console.error(e);
        // TODO: show error in UI
      }
    };

    update();
    const intervalId = setInterval(update, DEFAULT_POLL_TIMEOUT);
    return () => clearInterval(intervalId);
  }, [minecraftServiceStatusCode, api]);

  return (
    <>
      <div classnames={styles.state}>
        <div>Minecraft service state: {getServiceStatusDisplayText(minecraftServiceStatusCode)}</div>
        {minecraftServiceStatusCode !== ServiceStatusCodes.RUNNING ? null : (
          <>
            <div>Online players: {onlinePlayers.length === 0 ? 'None' : null}</div>
            {onlinePlayers.length === 0 ? null : (
              <table>
                <thead>
                  <th>Username</th>
                  <th>UUID</th>
                </thead>
                <tbody>
                  {onlinePlayers.map(({ username, uuid }) => (
                    <tr>
                      <td>{username}</td>
                      <td>{uuid}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
      <div classnames={styles.actions}>
        <Button onClick={handleStartMinecraftService}>Start Minecraft Service</Button>
        <Button onClick={handleStopMinecraftService}>Stop Minecraft Service</Button>
      </div>
    </>
  );
}

import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { getApi } from '../../api';
import ApiContext from '../../context/ApiContext';
import { isFunction } from '../../utils';
import {
  IDENTITY_POOL_ID,
  AWS_REGION,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
} from '../../constants';

Amplify.configure({
  Auth: {
      region: AWS_REGION,
      identityPoolId: IDENTITY_POOL_ID,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  }
});

function ApiProviderInner({ children, signOut, user}) {
  const [api, setApi] = useState();

  useEffect(() => {
    async function initApi() {
      const api = await getApi({ signOut, user });
      window.RIOT_ZONE_API = api;
      setApi(api);
    }
    initApi();
  }, [signOut, user]);

  return (
    <ApiContext.Provider value={api}>
      {!api ? null : (
        isFunction(children) ? children(api) : children
      )}
    </ApiContext.Provider>
  );
}

export default function ApiProvider({ children }) {
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <ApiProviderInner signOut={signOut} user={user}>
          {children}
        </ApiProviderInner>
      )}
    </Authenticator>
  );
}

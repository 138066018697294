import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const APP_TITLE = 'RiotZone';
const TITLE_PREFIX = window.location.hostname === 'localhost' ? '[Local] ' : '';
const TITLES = {
  '/admin': ' | Admin',
  '/admin/general': ' | Admin | General',
  '/admin/minecraft': ' | Admin | Minecraft',
  '/stream-overlay': ' | Stream Overlay',
};

export default function useDocumentTitle() {
  const location = useLocation();

  useEffect(() => {
    document.title = TITLE_PREFIX + APP_TITLE + (TITLES[location.pathname] ?? '');
  }, [location.pathname]);
}

import { EC2Client } from '@aws-sdk/client-ec2';
import { LambdaClient } from '@aws-sdk/client-lambda';
import { SSMClient } from '@aws-sdk/client-ssm';
import {
  EC2_INSTANCE_ID_RIOT_ZONE,
  LAMBDA_EXECUTE_MINECRAFT_COMMANDS,
  AWS_REGION,
  SSM_AWS_RUN_SCHELL_SCRIPT,
} from '../constants';
import { getCredentials } from './credentials';
import {
  describeInstances,
  startInstances,
  stopInstances,
} from './ec2';
import { invokeLambdaFunction } from './lambda';
import { sendAndAwaitCommand } from './ssm';

function makeApi({
  signOut,
  user,
  credentials,
  ec2Client,
  lambdaClient,
  ssmClient,
}) {
  return {
    signOut,
    user,
    credentials,
    executeMinecraftCommands: async (commands) => {
      const response = await invokeLambdaFunction(lambdaClient, LAMBDA_EXECUTE_MINECRAFT_COMMANDS, { commands });
      const payload = new TextDecoder().decode(response.Payload);
      return JSON.parse(payload);
    },
    getMinecraftServiceStatusCode: async () => {
      const response = await sendAndAwaitCommand(
        ssmClient,
        SSM_AWS_RUN_SCHELL_SCRIPT,
        EC2_INSTANCE_ID_RIOT_ZONE,
        { commands: ['service minecraft status'] },
      );
      return response.ResponseCode;
    },
    getRiotZoneServerStateCode: async () => {
      const response = await describeInstances(ec2Client, [EC2_INSTANCE_ID_RIOT_ZONE]);
      return response?.Reservations?.[0]?.Instances?.[0]?.State?.Code ?? null;
    },
    startMinecraftService: async () => {
      const response = await sendAndAwaitCommand(
        ssmClient,
        SSM_AWS_RUN_SCHELL_SCRIPT,
        EC2_INSTANCE_ID_RIOT_ZONE,
        { commands: ['service minecraft start'] },
      );
      if (response.ResponseCode === 0) {
        return;
      }
      throw response.StandardErrorContent;
    },
    startRiotZoneServer: async () => {
      const response = await startInstances(ec2Client, [EC2_INSTANCE_ID_RIOT_ZONE]);
      return response?.StartingInstances?.[0]?.CurrentState?.Code ?? null;
    },
    stopRiotZoneServer: async () => {
      const response = await stopInstances(ec2Client, [EC2_INSTANCE_ID_RIOT_ZONE]);
      return response?.StoppingInstances?.[0]?.CurrentState?.Code ?? null;
    },
    stopMinecraftService: async () => {
      const response = await sendAndAwaitCommand(
        ssmClient,
        SSM_AWS_RUN_SCHELL_SCRIPT,
        EC2_INSTANCE_ID_RIOT_ZONE,
        { commands: ['service minecraft stop'] },
      );
      if (response.ResponseCode === 0) {
        return;
      }
      throw response.StandardErrorContent;
    },
  };
}

export async function getApi({ signOut, user }) {
  const credentials = await getCredentials();
  const ec2Client = new EC2Client({ credentials, region: AWS_REGION });
  const lambdaClient = new LambdaClient({ credentials, region: AWS_REGION });
  const ssmClient = new SSMClient({ credentials, region: AWS_REGION });

  return makeApi({
    signOut,
    user,
    credentials,
    ec2Client,
    lambdaClient,
    ssmClient,
  });
}
